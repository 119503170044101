import "./style.scss"
import Icons from "../Icons"
import Status from "../Status"
import { useEffect, useRef, useState } from "react"
import { DateRangePicker } from 'react-date-range';
import { format, formatISO, isExists } from 'date-fns';
import NoResult from "../../assets/svg/NoResults.svg"
import { filedownload, getApiCallNew, postApiCallNew } from "../../service";
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const HistoryModal = ({OfferId, setLoading, MerchantName, OfferTitle, OfferStatus, setHistoryDet, OfferDetails}) => {

    const navigate = useNavigate();
    const [OffDet,setOffDet] = useState(OfferDetails)
    const [startDate,setStartDate] = useState('')
    const [endDate,setEndDate] = useState('')
    const [toggleCalendar,setToggleCalendar] = useState("")
    const [selectedOptions, setSelectedOptions] = useState([])
    const [sortConfig, setSortConfig] = useState({ key: '', ascending: true });
    const [dateState, setDateState] = useState([
      {
        startDate: new Date(),
        endDate: null,
        key: 'selection'
      }
    ]);

    const[downloadDDOpen,setDownloadDDOpen] = useState(false)
    const[FieldDD,setFieldDD] = useState(false)
    const[searchText,setSearchText] = useState('')
    const handleSearchChange = (event) => {
        setSearchText(event.target.value)
      };
    const [fieldValueList,setFieldValueList] = useState()
    const [selectedValues, setSelectedValues] = useState()
    const prevFieldVal = useRef()
    const FieldDDRef = useRef()
    const SDCalendarRef = useRef() 
    const EDCalendarRef = useRef() 
    useEffect(()=>{
      try{
      getApiCallNew(`/offers/history/filters/${OfferId}`).then(async (res) => {
        
          if (res?.status ===200) {
            let filterList = res.data.field
            setFieldValueList(filterList)
            setSelectedValues(new Set(filterList))
          }
          else if (res?.status===500){
            toast.error(res?.data?.error)
          }
          else if (res?.status===401){
            document.documentElement.style.overflow = 'auto';
            localStorage.removeItem("token")
            navigate('/login')
            setTimeout(() => {
              toast.error("Session has expired. Please login again.")
            }, 1000);
          }
          else {
            toast.error(res?.error)
          }
        })
      }
      catch (err) {
        console.log(err)
      } 
    },[]);

    function formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  }

const formatDateTime = (dateTimeString) => {
  const [datePart, timePart] = dateTimeString.split(' ');
  const [year, month, day] = datePart.split('-');
  const formattedDate = `${day}-${month}-${year.slice(-2)}`; 
  return `${formattedDate} ${timePart}`;
};
    
  useEffect(() => {
    
    const fetchOfferDetails = async () => {
      try {
        setLoading(true);
        let params = {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          filters: {
            ...(startDate && { "fromDate": formatDate(startDate) }),
            ...(endDate && { "toDate": formatDate(endDate) }),
            ...(selectedValues && { "field": [...selectedValues] })
          }
        }
          if( fieldValueList?.length - selectedValues?.size  === 1)
            params.filters.field = params.filters.field.filter((item)=>item !== "All")
          

        const res = await postApiCallNew(`/offers/history/${OfferId}`, params);
        const response = await res.json();
        if (res.status === 200) {
          response.offerHistoryList = response.offerHistoryList.map((item, index) => ({
            id: index + 1,
            ...item
          }));
          setOffDet(response.offerHistoryList);
        } else if (res.status === 500) {
          toast.error(response?.error);
        } else if (res.status === 401) {
          localStorage.removeItem("token");
          document.documentElement.style.overflow = 'auto';
          navigate('/login');
          setTimeout(() => {
            toast.error("Session has expired. Please login again.");
          }, 1000);
        } else {
          toast.error(res.error);
        }
      } catch (err) {
        console.error(err);
        toast.error("Failed to fetch offer details. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    if(startDate !== '' || endDate !== '' || prevFieldVal.current)
      fetchOfferDetails();
      prevFieldVal.current = selectedValues;
  }, [startDate, endDate, selectedValues]);
  

    useEffect(()=>{
      const handleClickOutside = (event) =>{
        if (
          FieldDDRef.current &&
          !FieldDDRef.current.contains(event.target) &&
          !document.querySelector('.FilterItem')?.contains(event.target)
          ) {
          setFieldDD(false);
          }      
        if (
          SDCalendarRef.current &&
          !SDCalendarRef.current.contains(event.target) &&
          EDCalendarRef.current &&
          !EDCalendarRef.current.contains(event.target)
        ) {
          setToggleCalendar('');          
          }
    
      }

      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
    },[])


    
    const handleFieldCheckboxChange = (event) => {
      const value = event.target.value;
      if (!selectedValues.has(value)) {
        const newSelectedValues = new Set(selectedValues);
        newSelectedValues.add(value);
        setSelectedValues(newSelectedValues);
      } else {
        const newSelectedValues = new Set(selectedValues);
        newSelectedValues.delete(value);
        setSelectedValues(newSelectedValues);
      }        
    }
    
    const handleOnchangeDate = (item,value) => {
      let cd = dateState[0].startDate.getTime()
      let ed = new Date(value.endDate).getTime()
      let selectedDate ;
      
      if(cd<ed)
        selectedDate = format(new Date(value.endDate),"dd MMM yyyy")
      else
        selectedDate = format(new Date(value.startDate),"dd MMM yyyy")
      if(item==="Start"){
        setStartDate(selectedDate)
        setEndDate('')
      }
      else if(item==="End"){
        setEndDate(selectedDate)
      }
      setToggleCalendar('')
      setDateState([value])
    }

    const handleFieldSelectAll =() =>{
      const filteredItems = fieldValueList.filter(item => 
        item.toLowerCase().includes(searchText.toLowerCase())
    );
      if (!isAllSelected()) {
        const newSelectedValues = new Set(selectedValues);
        filteredItems.forEach(item => newSelectedValues.add(item));
        setSelectedValues(newSelectedValues); 
      } else {
          const newSelectedValues = new Set(selectedValues);
          filteredItems.forEach(item => newSelectedValues.delete(item));
          setSelectedValues(newSelectedValues);
      }
    }  
    let tableHeader = [
        { label: 'Date & Time', value: 'actionDateTime' },
        { label: 'User', value: 'userName' },
        { label: 'Field', value: 'field' },
        { label: 'Action', value: 'action' },
        { label: 'Previous', value: 'previousValue' },
        { label: 'New', value: 'newValue' },
        { label: 'Comments/ Review', value: 'comments' }
      ];

    function handleDownloadCSV(id){
      try{
        filedownload(`/offers/history/download/${id}`).then(async (res) => {
      
          if (res?.status === 200) {
            
          }
          else if (res?.status===500){
            toast.error(res?.data?.error)
          }
          else if (res?.status===401){
            document.documentElement.style.overflow = 'auto';
            localStorage.removeItem("token")
            navigate('/login')
            setTimeout(() => {
              toast.error("Session has expired. Please login again.")
            }, 1000);
          }
          else {
            toast.error(res?.error)
          }
          })
        }
        catch (err) {
          console.log(err)
        } 
    }
    function handleDownloadPDF(id){

    }

    const handleSelectAll =() => {
      if(OffDet?.length === selectedOptions.length){
        setSelectedOptions([])
      }
      else{
        let ss = OffDet?.map((item)=>item.id)
        setSelectedOptions(ss)
      }
    }
    const handleCheckboxChange = (option) => {
      if(selectedOptions.includes(option)){
        setSelectedOptions(selectedOptions.filter(item=>item!==option))
      }
      else{
        setSelectedOptions([...selectedOptions,option])
      }
      
    }    
    const sortTable = (key) => {
      const newOrder = sortConfig.key === key ? !sortConfig.ascending : true;
      setSortConfig({ key, ascending: newOrder });
      const sortedData = [...OffDet].sort((a, b) => {
        let valueA = a[key];
        let valueB = b[key];
        if (key === 'DateNTime') {
          valueA = new Date(valueA);
          valueB = new Date(valueB);
        }
        if (valueA === undefined || valueB === undefined) {
          console.error(`Cannot compare: ${valueA}, ${valueB}`);
          return 0;
        }
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return newOrder
            ? valueA.localeCompare(valueB) 
            : valueB.localeCompare(valueA);
        }
        return newOrder ? valueA - valueB : valueB - valueA;
      });
      setOffDet(sortedData);
    };
   
    function isSubset(list1, list2) {
      return list1.some(item => list2.has(item));
    }
    function isSameset(list1, list2) {
      return list1.filter(item => item.trim() !== "All").every(item => list2.has(item));
  }
  
  
    function isAllSelected(){
      const filteredItems = fieldValueList.filter(option => 
        option.toLowerCase().includes(searchText.toLowerCase())
    );
    return filteredItems.every(item => selectedValues.has(item)); 
    }    

    const filteredOptions = fieldValueList?.filter(option =>
      option.trim().toLowerCase() !== "all" && option.toLowerCase().includes(searchText.toLowerCase())
    ) || [];
    
    const optionsToDisplay = filteredOptions.length > 0 ? ["All", ...filteredOptions] : filteredOptions;
    

    return <div className="HistoryModal">
        <div className="Maindiv">
            <div className="Heading">Offer History - {OfferId}
                <div className="RightIcons">
                    {OfferDetails?.length >= 1 && <div style={{position: "relative"}} onClick={()=>{setDownloadDDOpen(!downloadDDOpen)}}>
                        <Icons name="download" width="32" height="32" />
                        {downloadDDOpen && 
                        <div className={`actions-dropdownlist`}>
                            <button className='action-new' onClick={() => {
                                handleDownloadCSV(OfferId);
                            }}>
                                Download as .CSV
                            </button>
                            <button className='action-new' onClick={() => {
                                handleDownloadPDF();
                            }}>
                                Download as .Pdf
                            </button>
                        </div>
                        } 
                     </div>}
                    <div onClick={()=>{setHistoryDet({});document.documentElement.style.overflow = 'auto'; }}><Icons name="cancel-grey"  width="32" height="32" /></div>
                </div>
            </div>
            <div className="Details">
            <div className="MerhantDet">
                {MerchantName} - {OfferTitle}
                <div className="OfferStatus">
                {(() => {
                    switch(OfferStatus?.trim()) {
                      case "Live":
                        return <Status text="Live" textColor="#14532D" bgColor="#F0FDF4"/>;
                      case "Rejected":
                        return <Status text="Rejected" textColor="#DC2626" bgColor="#FEF2F2"/>;
                      case "Draft":
                        return <Status text="Draft" textColor="#374151" bgColor="#F3F4F6"/>;
                      case "Requested to Pause":
                        return <Status text="Requested to Pause" textColor="#B010B6" bgColor="#FFE8FB"/>;
                      case "Requested to Resume":
                        return <Status text="Requested to Resume" textColor="#B010B6" bgColor="#FFE8FB"/>;
                      case "Paused":
                        return <Status text="Paused" textColor="#CFAF0F" bgColor="#FEFFE8"/>;
                      case "Expired":
                        return <Status text="Expired" textColor="#CC5120" bgColor="#FFF3E8"/>;
                      case "Scheduled":
                        return <Status text="Scheduled" textColor="#002D8E" bgColor="#E6EDFD" />;
                      case "Resume InProgress":
                        return <Status text="Resume InProgress" textColor="#001E5E" bgColor="#C3E7FE"/>;
                      case "Pause InProgress":
                        return <Status text="Pause InProgress" textColor="#001E5E" bgColor="#C3E7FE" />;
                      case "Pending for Approval":
                        return <Status text="Pending for Approval" textColor="#D97706" bgColor="#FFFBEB"/>;
                      default:
                        return <Status text={OfferStatus} textColor="#CFAF0F" bgColor="#FFF3E8"/>;
                    }
                  })()}
                </div>
            </div>
            {OfferDetails?.length >= 1 && <div className="Filters">
                  <div className="TimeStamp">
                    <div>Date : </div>
                    <div className="calendar" ><Icons name="calendar" width="24" height="24" /> </div>
                    <div ref={SDCalendarRef} className={`dateVal ${startDate ? 'act' : ''}`} onClick={(e)=>{
                        setToggleCalendar("Start");
                      }}>{startDate ? <>{startDate}</> :"Select Date"}
                      {toggleCalendar === "Start" && <div style={{position:"absolute", right:"0", zIndex:"50"}}>
                        <div >
                        <DateRangePicker
                          editableDateInputs={true}
                          onChange={item => handleOnchangeDate("Start",item.selection)}
                          focusedRange={[0,1]}
                          moveRangeOnFirstSelection={false}
                          ranges={dateState}
                          showSelectionPreview={true}
                          months={1}
                          direction="horizontal"
                        />
                        </div>
                      </div>}
                    </div>
                    <div className="calendar-arrow"><Icons name="calendar-arrow" width="24" height="24" /></div> 
                    <div  ref={EDCalendarRef} className={`dateVal ${endDate ? 'act' : ''}`} onClick={(e)=>{
                        setToggleCalendar("End");
                      }}>{endDate ? <>{endDate}</> :"Select Date"}
                      {toggleCalendar==="End" && <div style={{position:"absolute", right:"0", zIndex:"50"}}>
                        <div>
                        <DateRangePicker
                          editableDateInputs={true}
                          onChange={item => handleOnchangeDate("End",item.selection)}
                          focusedRange={[0,1]}
                          moveRangeOnFirstSelection={false}
                          ranges={dateState}
                          showSelectionPreview={true}
                          months={1}
                          minDate={startDate ? new Date(startDate) :new Date("01 Jan 1924")}
                          direction="horizontal"
                        /></div>
                      </div>}
                    </div>
                  </div>
                  <div className="Separator">|</div>
                  <div className="FilterItem" onClick={(e)=>{e.stopPropagation();setFieldDD(!FieldDD)}}>Field & Action : All <Icons name="nav-arrow-down" height="20" width="20" /> </div>

                  <div>
                    {FieldDD &&
                    <div ref={FieldDDRef} className="FieldDD">
                      <div className='drop-search'>
                        <div><Icons
                          name="search"
                          width="18"
                          height="18"
                          fill="#6B7280"
                        /></div>
                        <div className='dropdown-inputbox'>
                        <input 
                            type="text" 
                            placeholder="Search..." 
                            value={searchText}
                            onChange={(e) => handleSearchChange(e)}
                            className="dropdown-search"
                          />
                        </div>
                      </div>
                      <div className='hr-line ml-10 mb-24'></div>
                    <div className="dropdown custom-scrollbar">
                      <div className='val-dropdown'>
                      {optionsToDisplay
                        .map(option => (
                          <div key={option}>
                            {option.trim() === "All" ? (
                              <label className={`container`}>
                                <input
                                  type="checkbox"
                                  checked={selectedValues.size > 1 && isSubset(
                                    fieldValueList.filter(item => item.trim().toLowerCase() !== "all" && item.toLowerCase().includes(searchText.toLowerCase())),
                                    selectedValues
                                  )}
                                  onChange={() => handleFieldSelectAll()}
                                />
                                <span className={`${selectedValues.size >= 1 && isSameset(
                                  fieldValueList.filter(item => item.trim().toLowerCase() !== "all" && item.toLowerCase().includes(searchText.toLowerCase())),
                                  selectedValues
                                ) ? "checkmark1" : "checkmark2"}`}></span>
                                All
                              </label>
                            ) : (
                              <label className="container">
                                <input
                                  type="checkbox"
                                  value={option}
                                  checked={selectedValues?.has(option)}
                                  onChange={(e) => handleFieldCheckboxChange(e)}
                                />
                                <span className="checkmark1"></span>
                                {option}
                              </label>
                            )}
                          </div>
                        ))}

                      </div>
                    </div>
                  </div>}
                </div>
              </div>}
            {OfferDetails?.length >= 1 ? <div className="HistoryTable">
              {OffDet?.length === 0 ? 
                      <div className="NoResults">
                      <img src={NoResult}/>
                      <div> No offer history available.</div>
                    </div>
                      :
                <table>
                  <thead>
                      <td>
                      <label className="container">
                          <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={selectedOptions?.length >= 1}
                          />
                          <span className={selectedOptions?.length === OffDet?.length ? "checkmark1" : "checkmark2"}></span>
                      </label>
                      </td>
                      {tableHeader && tableHeader.map(
                      ({ label, value }) => {
                          return (<td onClick={()=>{
                            
                              
                                sortTable(value)
                              
                          }}>
                          <div>
                              {label}
                              {
                              <span className='sort' >
                                  <div><Icons name="arrow-up" width={6} height={8} /></div>
                                  <div><Icons name="arrow-down" width={6} height={8} /></div>
                              </span>
                              }
                          </div>
                          </td>)
                      }
                      )}
                  </thead>
                  <tbody>
                      {OffDet.length >= 1 && OffDet?.map((offerDetails) => {
                      return (<tr>
                          <td style={{width:"4%"}}>
                          <label className="container">
                          <input
                              type="checkbox"
                              checked={selectedOptions?.includes(offerDetails.id)}
                              onChange={() => {handleCheckboxChange(offerDetails.id)}}
                          />
                          <span className="checkmark1"></span>
                          </label>
                          </td>
                          <td style={{width:"14%"}}>{formatDateTime(offerDetails.actionDateTime)}</td>
                          <td style={{width:"11%"}}>{offerDetails.userName}</td>
                          <td style={{width:"11%"}}>{offerDetails.field}</td>
                          <td style={{width:"14%"}}>{offerDetails.action}</td>
                          <td style={{width:"14%"}}><div dangerouslySetInnerHTML={{ __html: offerDetails.previousValue }} /></td>
                          <td style={{width:"14%"}}><div dangerouslySetInnerHTML={{ __html: offerDetails.newValue }} /></td>
                          <td style={{width:"18%"}}>{offerDetails.comments}</td>
                      </tr>)
                      })}
                  </tbody>    
              </table>
              }
            </div> : 
              <div className="NoResults">
                <img src={NoResult}/>
                <div> No offer history available.<br />
                This offer was created outside of the Offer Management Platform </div>
              </div>}
          </div>
        </div>
    </div>
}

export default HistoryModal