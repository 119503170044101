import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format, formatISO, isExists } from 'date-fns';
const Icons = React.lazy(() => import('../../components/Icons'));

function FilterSidebar({ startDate,
  setStartDate,
  endDate,
  setEndDate,
  startendDate,
  setStartendDate,
  endendDate,
  setEndendDate,searchedValues, setSearchedValues, searchTerms, setSearchTerms, handleSearchChange, options, dropdowns, setDropdowns,optionValues,setOptionValues,selectedValues,setSelectedValues, toggleFilter,isFilterOpen, applyFilter,clearFilters,clearSidebarFilters}) {
  const [isStartFocused, setIsStartFocused] = useState(false)
  const [isEndFocused, setIsEndFocused] = useState(false)
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);
  const [dateendState, setDateendState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [calendarendOpen, setCalendarendOpen] = useState(false)
  const calendarRef = useRef(null)
  const calendarendRef = useRef(null)
  const [validFrom,setValidFrom] = useState(null)
  const [validTo,setValidTo] = useState(null)
  
  const [changeDate, setChangeDate] = useState(null)

  const toggleDropdown = (dropdown) => {
    setDropdowns({ ...dropdowns, [dropdown]: !dropdowns[dropdown] });
  };

  // Function to check if all options in a dropdown are selected
  const isAllSelected = (dropdownKey, word='') => {
    console.log(selectedValues[dropdownKey]?.filter(option => option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase())), optionValues[dropdownKey]?.filter(option => option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase())))
    if (word==='') {
    return optionValues[dropdownKey].length > 0 && selectedValues[dropdownKey].length === optionValues[dropdownKey]?.filter(option => option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase())).length;
    }
    else {
      return optionValues[dropdownKey].length > 0 && selectedValues[dropdownKey]?.filter(option => option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase())).length === optionValues[dropdownKey]?.filter(option => option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase())).length;
    }
  };

  // Function to handle the "Select All" logic
  const handleSelectAll = (dropdownKey,word='') => {
    if (isAllSelected(dropdownKey,word)) {
      console.log("remove",selectedValues[dropdownKey]?.filter(option => !option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase())))
      setSelectedValues({ ...selectedValues, [dropdownKey]: [...new Set(selectedValues[dropdownKey]?.filter(option => !option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase())))] });
      setSearchedValues({ ...searchedValues, [dropdownKey]: [] });
    } else {
      // console.log("add")
      setSelectedValues({ ...selectedValues, [dropdownKey]: [...new Set(selectedValues[dropdownKey].concat(optionValues[dropdownKey]?.filter(option => option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase()))))] });
      setSearchedValues({ ...searchedValues, [dropdownKey]: optionValues[dropdownKey]?.filter(option => option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase())) });

    }
  };


  const handleCheckboxChange = (event, dropdown) => {
    const { value, checked } = event.target;
    setSelectedValues(prevState => {
      if (prevState && prevState[dropdown]) {
        const updatedValues = checked
          ? [...prevState[dropdown], value]
          : prevState[dropdown].filter(item => item !== value);
        return { ...prevState, [dropdown]: [...new Set(updatedValues)] };
      }
    });
    setSearchedValues(prevState => {
      if (prevState && prevState[dropdown]) {
        const updatedValues = checked
          ? [...prevState[dropdown], value]
          : prevState[dropdown].filter(item => item !== value);
        return { ...prevState, [dropdown]: [...new Set(updatedValues)] };
      }
    });
  };

  const handleCalendarOpen = (date,key) => {
    // alert(date)
    setChangeDate(date)
    if (date === "start") {
      setIsStartFocused(true);
      setIsEndFocused(false)
    }else {
      setIsStartFocused(false);
      setIsEndFocused(true)
    }
    const values = selectedValues[key];
    setDateState([{
      startDate: values.length>0? (values[0]?.startDate && values[0]?.startDate !==null)?new Date(values[0]?.startDate):new Date():new Date(),
      endDate: values.length>0? (values[0]?.endDate && values[0]?.endDate !==null)? new Date(values[0]?.endDate):(startDate && startDate !==null)?new Date(startDate):null:null,
      key: 'selection'
    }])
    setCalendarOpen(true)
  }
  const handleOnchangeDate = (item,key) => {
    // console.log(item)
    if (changeDate === "start") {
      const date = new Date(item[0].startDate);
      setValidFrom(formatISO(date))
      const date3 = format(item[0].startDate,"dd MMM yyyy");
      setStartDate(date3)
      setValidTo(null)
      setEndDate(null)
      setSelectedValues({ ...selectedValues,[key]: [{startDate:date3,endDate:null}] });
      // setDateState(dateState)
    }
    else {

      
      if (startDate===null) {
        const date2 = new Date(item[0].endDate);
        setValidTo(formatISO(date2))
        const date4 = format(item[0].endDate,"dd MMM yyyy");
        setEndDate(date4)
        setSelectedValues({ ...selectedValues, [key]: [{startDate:null,endDate:date4}] });
      }
      else {
        const date = new Date(item[0].startDate);
        setValidFrom(formatISO(date))
        const date3 = format(item[0].startDate,"dd MMM yyyy");
        setStartDate(date3)
        const date2 = new Date(item[0].endDate);
        setValidTo(formatISO(date2))
        const date4 = format(item[0].endDate,"dd MMM yyyy");
        setEndDate(date4)
        setSelectedValues({ ...selectedValues, [key]: [{startDate:date3,endDate:date4}] });
      }
      // setDateState(dateState)
    }
    setDateState(item)
    
    
    setCalendarOpen(false)
    // if (item[0].startDate===item[0].endDate) {
    //   setEndDate(null)
    //   // setValidTo(null)
    // }
  }
  const handleCalendarendOpen = (date,key) => {
    // alert(date)
    setChangeDate(date)
    if (date === "start") {
      setIsStartFocused(true);
      setIsEndFocused(false)
    }else {
      setIsStartFocused(false);
      setIsEndFocused(true)
    }
    const values = selectedValues[key];
    setDateendState([{
      startDate: values.length>0? (values[0]?.startDate && values[0]?.startDate !==null)?new Date(values[0]?.startDate):new Date():new Date(),
      endDate: values.length>0? (values[0]?.endDate && values[0]?.endDate !==null)? new Date(values[0]?.endDate):(startendDate && startendDate !==null)?new Date(startendDate):null:null,
      key: 'selection'
    }])
    setCalendarendOpen(true)
  }
  const handleOnchangeendDate = (item,key) => {
    // console.log(item)
    if (changeDate === "start") {
      const date = new Date(item[0].startDate);
      const date3 = format(item[0].startDate,"dd MMM yyyy");
      setStartendDate(date3)
      setEndendDate(null)
      setSelectedValues({ ...selectedValues,[key]: [{startDate:date3,endDate:null}] });
      // setDateState(dateState)
    }
    else {

      
      if (startendDate===null) {
        const date2 = new Date(item[0].endDate);
        const date4 = format(item[0].endDate,"dd MMM yyyy");
        setEndendDate(date4)
        setSelectedValues({ ...selectedValues, [key]: [{startDate:null,endDate:date4}] });
      }
      else {
        const date = new Date(item[0].startDate);
        const date3 = format(item[0].startDate,"dd MMM yyyy");
        setStartendDate(date3)
        const date2 = new Date(item[0].endDate);
        const date4 = format(item[0].endDate,"dd MMM yyyy");
        setEndendDate(date4)
        setSelectedValues({ ...selectedValues, [key]: [{startDate:date3,endDate:date4}] });
      }
      // setDateState(dateState)
    }
    setDateendState(item)
    
    
    setCalendarendOpen(false)
    // if (item[0].startDate===item[0].endDate) {
    //   setEndDate(null)
    //   // setValidTo(null)
    // }
  }
  useEffect(()=> {
    if(optionValues && Object.keys(optionValues).includes("country")){setOptionValues(optionValues => ({
      ...optionValues,
      country: optionValues.country.map(country =>
        country === "" ? "\u00A0" : country 
      )
    }));}
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setCalendarOpen(false);
      }
      if (calendarendRef.current && !calendarendRef.current.contains(event.target)) {
        setCalendarendOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  },[])


  return (
    <div className={`filter-sidebar ${isFilterOpen ? 'open' : 'closed'}`}>
        <div className='flex data-filter'>
            <div>Data Filters</div>
            <div className="close-button" onClick={toggleFilter}>
                <Icons     
                    name= "cancel"
                    width= "40px"
                    height= "40px"
                    fill= ""
                />
            </div>
        </div>
      <div className='mt-74 filter-scrollbar custom-scrollbar'>
      {Object.keys(dropdowns).map((dropdownKey,index) => (
        <div key={dropdownKey} className="filter-item">
          <div
            className="filter-label"
            onClick={() => toggleDropdown(dropdownKey)}
          >
            <div>
              {options[index]}
              {/* {dropdownKey.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} */}
            </div>
            <div className={`droparr ${dropdowns[dropdownKey] && "arr-rot"}`}>
                <Icons     
                name= "nav-arrow-down"
                width= "20px"
                height= "20px"
                fill= "#374151"
                />
            </div>
          </div>
          <div className='hr-line'></div>
          {dropdowns[dropdownKey] && (
            (options[index]==="Start Date" || options[index]==="Last Updated Date")?
              <div className='calendarWrap' ref={calendarRef} key={Math.random()}>
              <div className='flex'>
                <span className='mr-16' 
                // onClick={() => handleCalendarOpen("start")}
                >
                  <Icons     
                  name= "calendar"
                  width= "24"
                  height= "24"
                  fill= "#004BEC"
                  />
                </span>
                <input
                type="text"
                className={`date-input`}
                placeholder="Select date"
                value={selectedValues[dropdownKey].length>0? selectedValues[dropdownKey][0]?.startDate:null}
                onClick={() => handleCalendarOpen("start",dropdownKey)}
                readOnly
                />
                <span className='mlr-16'>
                  <Icons     
                  name= "calendar-arrow"
                  width= "24"
                  height= "24"
                  fill= "#004BEC"
                  />
                </span>
                {/* <span className='mr-18 cur' onClick={() => handleCalendarOpen("end")}>
                  <Icons     
                  name= "calendar"
                  width= "24"
                  height= "24"
                  fill= "#004bec"
                  />
                </span> */}
                <input
                type="text"
                className={`date-input`}
                placeholder="Select date"
                value={selectedValues[dropdownKey].length>0? selectedValues[dropdownKey][0]?.endDate:null}
                onClick={() => handleCalendarOpen("end",dropdownKey)}
                readOnly
                />
              </div>
              {/* {calendarOpen && */}
              <div style={{display: (calendarOpen)? "":"none"}}>
              <DateRangePicker
                editableDateInputs={true}
                onChange={item => handleOnchangeDate([item.selection],dropdownKey)}
                moveRangeOnFirstSelection={false}
                ranges={dateState}
                showSelectionPreview={true}
                months={1}
                direction="horizontal"
                focusedRange={isStartFocused? [0,0]:[0,1]}
                minDate={isEndFocused? selectedValues[dropdownKey].length>0? new Date(selectedValues[dropdownKey][0]?.startDate):new Date("01 Jan 1924"):new Date("01 Jan 1924")}
              /></div>
              
              {/* } */}
              </div>
            : options[index]==="End Date" ?
            <div className='calendarWrap' ref={calendarendRef} key={Math.random()}>
              <div className='flex'>
                <span className='mr-16' 
                // onClick={() => handleCalendarOpen("start")}
                >
                  <Icons     
                  name= "calendar"
                  width= "24"
                  height= "24"
                  fill= "#004BEC"
                  />
                </span>
                <input
                type="text"
                className={`date-input`}
                placeholder="Select date"
                value={selectedValues[dropdownKey].length>0? selectedValues[dropdownKey][0]?.startDate:null}
                onClick={() => handleCalendarendOpen("start",dropdownKey)}
                readOnly
                />
                <span className='mlr-16'>
                  <Icons     
                  name= "calendar-arrow"
                  width= "24"
                  height= "24"
                  fill= "#004BEC"
                  />
                </span>
                {/* <span className='mr-18 cur' onClick={() => handleCalendarOpen("end")}>
                  <Icons     
                  name= "calendar"
                  width= "24"
                  height= "24"
                  fill= "#004bec"
                  />
                </span> */}
                <input
                type="text"
                className={`date-input`}
                placeholder="Select date"
                value={selectedValues[dropdownKey].length>0? selectedValues[dropdownKey][0]?.endDate:null}
                onClick={() => handleCalendarendOpen("end",dropdownKey)}
                readOnly
                />
              </div>
              {/* {calendarOpen && */}
              <div style={{display: (calendarendOpen)? "":"none"}}>
              <DateRangePicker
                editableDateInputs={true}
                onChange={item => handleOnchangeendDate([item.selection],dropdownKey)}
                moveRangeOnFirstSelection={false}
                ranges={dateendState}
                showSelectionPreview={true}
                months={1}
                direction="horizontal"
                focusedRange={isStartFocused? [0,0]:[0,1]}
                minDate={isEndFocused? selectedValues[dropdownKey].length>0 && new Date(selectedValues[dropdownKey][0]?.startDate):new Date("01 Jan 1924")}
              /></div>
              
              {/* } */}
              </div>
            :
            <>
            {(options[index]==="Country" || options[index]==="Offer Source") &&
            <><div className='drop-search'>
            <div><Icons
              name="search"
              width="18"
              height="18"
              fill="#6B7280"
            /></div>
            <div className='dropdown-inputbox'>
            <input 
                type="text" 
                placeholder="Search..." 
                value={searchTerms[dropdownKey]}
                onChange={(e) => handleSearchChange(e, dropdownKey)}
                className="dropdown-search"
              />
            </div>
            </div>
            <div className='hr-line ml-10 mb-24'></div></>}
            <div className="dropdown custom-scrollbar" key={dropdownKey}>
              {/* Replace these options with actual data */}
              {
               optionValues[dropdownKey]?.filter(option => option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase())).length>=1 && 
               (searchTerms[dropdownKey]===''?(
                
                ( selectedValues[dropdownKey]?.length>=1 && selectedValues[dropdownKey]?.length !== optionValues[dropdownKey]?.length)?
              
              <label className={`container ${selectedValues[dropdownKey].length !== optionValues[dropdownKey]?.length && "all-select"}`}>
                <input
                  type="checkbox"
                  checked={isAllSelected(dropdownKey,searchTerms[dropdownKey])}
                    onChange={() => handleSelectAll(dropdownKey,searchTerms[dropdownKey])}
                />
                <span className="checkmark1"><div className='checkmate'></div></span>
                All
              </label>
              :
              <label className={`container`}>
                <input
                  type="checkbox"
                  checked={isAllSelected(dropdownKey,searchTerms[dropdownKey])}
                    onChange={() => handleSelectAll(dropdownKey,searchTerms[dropdownKey])}
                />
                <span className="checkmark1"></span>
                All
              </label>
              ):
              (
                
                ( selectedValues[dropdownKey]?.filter(option => option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase()))?.length>=1 && selectedValues[dropdownKey]?.filter(option => option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase()))?.length !== optionValues[dropdownKey]?.filter(option => option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase()))?.length)?
              
              <label className={`container ${selectedValues[dropdownKey]?.filter(option => option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase()))?.length !== optionValues[dropdownKey]?.filter(option => option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase()))?.length && "all-select"}`}>
                <input
                  type="checkbox"
                  checked={isAllSelected(dropdownKey,searchTerms[dropdownKey])}
                    onChange={() => handleSelectAll(dropdownKey,searchTerms[dropdownKey])}
                />
                <span className="checkmark1"><div className='checkmate'></div></span>
                All
              </label>
              :
              <label className={`container`}>
                <input
                  type="checkbox"
                  checked={isAllSelected(dropdownKey,searchTerms[dropdownKey])}
                    onChange={() => handleSelectAll(dropdownKey,searchTerms[dropdownKey])}
                />
                <span className="checkmark1"></span>
                All
              </label>
              ))}
              <div className='val-dropdown'>
              {optionValues[dropdownKey]?.filter(option => option.toLowerCase().includes(searchTerms[dropdownKey].toLowerCase()))
              .map(option => (
                <>
                <label className="container" key={option}>
                  <input
                    type="checkbox"
                    value={option}
                    checked={selectedValues[dropdownKey]?.includes(option)}
                    onChange={(e) => handleCheckboxChange(e, dropdownKey)}
                  />
                  <span className="checkmark1"></span>
                  {option}
                </label>
                {/* <label key={option}>
                  <input
                    type="checkbox"
                    value={option}
                    checked={selectedValues[dropdownKey]?.includes(option)}
                    onChange={(e) => handleCheckboxChange(e, dropdownKey)}
                  />
                  {option}
                </label> */}
                </>
              ))}
              </div>
            </div>
            </>
          )}
        </div>
      ))}
      </div>
      <div className='decide'>
          <div style={{marginLeft:"0",cursor:"pointer",display:"flex",alignItems:"center"}} onClick={clearFilters}>
            <span style={{color:"#EF4444",fontSize:"16px",marginLeft:"0",fontWeight:"500"}}>Clear all</span>
          </div>
          <div className="apply-button" onClick={applyFilter}>Apply</div>
      </div>
      
    </div>
  );
};

export default FilterSidebar;

